import React from "react";
import Logo from "../asset/logo.jpeg";
import Logo1 from "../asset/unnamed.jpg";

const CliCommand = () => {
  const a = ">>";
  const b = "'${CROSSFI_WALLET_ADDRESS}";
  const c = "'export CROSSFI_WALLET_ADDRESS";
  const d = "'${CROSSFI_VALOPER_ADDRESS}";
  const e = ">";
  const f = "<";

  return (
    <div id="cli-command">
      <div className="im">
        <img
          src={Logo}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
        <img
          src={Logo1}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
      </div>
      <h2>CLI Command</h2>
      <h1 className="lh-base text-break">
        Guidance for Validator <br />
        Create wallet
      </h1>
      <p>
        To create a new wallet, you can use the command below. Don’t forget to
        save the mnemonic
      </p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid keys add $WALLET
      </p>
      <p>To recover your wallet using a seed phrase</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid keys add $WALLET --recover
      </p>
      <p>Show your wallet list</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid keys list
      </p>
      <h1 className="lh-base">Save wallet info</h1>
      <p>Add wallet and validator address into variables</p>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          CROSSFI_WALLET_ADDRESS=$(crossfid keys show $WALLET -a)
          <br />
          CROSSFI_VALOPER_ADDRESS=$(crossfid keys show $WALLET --bech val -a)
          <br />
          echo {c}={b} {a}
          $HOME/.bash_profile
          <br /> echo 'export CROSSFI_VALOPER_ADDRESS={d} {a}{" "}
          $HOME/.bash_profile
          <br /> source $HOME/.bash_profile
        </p>
      </div>
      <h1 className="lh-base">Fund your wallet</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        N/A
      </p>
      <h1 className="lh-base">Create validator</h1>
      <p>check your wallet balance:</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid query bank balances $CROSSFI_WALLET_ADDRESS
      </p>
      <p>To create your validator run command below</p>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          crossfid tx staking create-validator \<br />
          --amount 1000000mpx \<br />
          --from $WALLET \<br />
          --commission-max-change-rate "0.1" \<br />
          --commission-max-rate "0.2" \<br />
          --commission-rate "0.1" \<br />
          --min-self-delegation "1" \<br />
          --pubkey $(crossfid tendermint show-validator) \<br />
          --moniker $NODENAME \<br />
          --chain-id $CROSSFI_CHAIN_ID
          <br />
          --gas auto \<br />
          --gas-adjustment 1.5 \<br />
          --gas-prices 10000000000000mpx
        </p>
      </div>
      <h1 className="lh-base">Check your validator key</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          [[ $(crossfid q staking validator $CROSSFI_VALOPER_ADDRESS -oj | jq -r
          .consensus_pubkey.key) = $(crossfid status | jq -r
          <br /> .ValidatorInfo.PubKey.value) ]] && echo -e
          "\n\e[1m\e[32mTrue\e[0m\n" || echo -e "\n\e[1m\e[31mFalse\e[0m\n"
        </p>
      </div>
      <h1 className="lh-base">Get list of validators</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          crossfid q staking validators -oj --limit=3000 | jq '.validators[] |
          select(.status="BOND_STATUS_BONDED")' | jq -r <br />
          '(.tokens|tonumber/pow(10; 6)|floor|tostring) + " \t " +
          .description.moniker' | sort -gr | nl
        </p>
      </div>
      <h1 className="lh-base">Usefull commands</h1>
      <h1 className="lh-base">Service management</h1>
      <p>Check logs</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        journalctl -fu crossfid -o cat
      </p>
      <p>Start service</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        sudo systemctl start crossfid
      </p>
      <p>Stop service</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        sudo systemctl stop crossfid
      </p>
      <p>Restart service</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        sudo systemctl restart crossfid
      </p>
      <h1 className="lh-base">Node info</h1>
      <p>Synchronization info</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid status 2{e}&1 | jq .SyncInfo
      </p>
      <p>Validator info</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid status 2{e}&1 | jq .ValidatorInfo
      </p>
      <p>Node info</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid status 2{e}&1 | jq .NodeInfo
      </p>
      <p>Show node id</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid tendermint show-node-id
      </p>
      <h1 className="lh-base">Wallet operations</h1>
      <p>List of wallets</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid keys list
      </p>
      <p>Recover wallet</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid keys add $WALLET --recover
      </p>
      <p>Delete wallet</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid keys delete $WALLET
      </p>
      <p>Get wallet balance</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid query bank balances $CROSSFI_WALLET_ADDRESS
      </p>
      <p>Transfer funds</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid tx bank send $CROSSFI_WALLET_ADDRESS {f}
        TO_CROSSFI_WALLET_ADDRESS{e} 1000000mpx --gas auto --gas-adjustment 1.5
        --gas-prices <br />
        10000000000000mpx
      </p>
      <h1 className="lh-base">Voting</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid tx gov vote 1 yes --from $WALLET --chain-id=$CROSSFI_CHAIN_ID
        --gas auto --gas-adjustment 1.5 --gas-prices
        <br /> 10000000000000mpx
      </p>
      <h1 className="lh-base">Staking, Delegation and Rewards</h1>
      <p>Delegate stake</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid tx staking delegate $CROSSFI_VALOPER_ADDRESS 1000000mpx
        --from=$WALLET --chain-id=$CROSSFI_CHAIN_ID --gas auto --gas-
        <br />
        adjustment 1.5 --gas-prices 10000000000000mpx
      </p>
      <p>Redelegate stake from validator to another validator</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid tx staking redelegate {f}srcValidatorAddress{e} {f}
        destValidatorAddress{e} 1000000mpx --from=$WALLET
        --chain-id=$CROSSFI_CHAIN_ID <br />
        --gas auto --gas-adjustment 1.5 --gas-prices 10000000000000mpx
      </p>
      <p>Withdraw all rewards</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid tx distribution withdraw-all-rewards --from=$WALLET
        --chain-id=$CROSSFI_CHAIN_ID --gas auto --gas-adjustment 1.5 --gas-
        <br />
        prices 10000000000000mpx
      </p>
      <p>Withdraw rewards with commision</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        crossfid tx distribution withdraw-rewards $CROSSFI_VALOPER_ADDRESS
        --from=$WALLET --commission --chain-id=$CROSSFI_CHAIN_ID --gas auto
        <br />
        --gas-adjustment 1.5 --gas-prices 10000000000000mpx
      </p>
      <h1 className="lh-base">Validator management</h1>
      <p>Edit validator</p>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          crossfid tx staking edit-validator \<br />
          --moniker=$NODENAME \<br />
          --identity={f}your_keybase_id{e} \<br />
          --website="{f}your_website{e}" \<br />
          --details="{f}your_validator_description{e}" \<br />
          --chain-id=$CROSSFI_CHAIN_ID \<br />
          --from=$WALLET
          <br />
          --gas auto \<br />
          --gas-adjustment 1.5 \<br />
          --gas-prices 10000000000000mpx
        </p>
      </div>
      <p>Unjail validator</p>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          crossfid tx slashing unjail \<br />
          --broadcast-mode=block \<br />
          --from=$WALLET \<br />
          --chain-id=$CROSSFI_CHAIN_ID \<br />
          --gas auto \<br />
          --gas-adjustment 1.5 \<br />
          --gas-prices 10000000000000mpx
        </p>
      </div>
    </div>
  );
};

export default CliCommand;

import React, { useState } from "react";
import Logo from "../asset/logo.jpeg";

const NavBar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleToggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="/">
        <img
          src={Logo}
          alt="Logo"
          style={{
            borderRadius: "50px",
            marginRight: "1rem",
            marginLeft: "1rem",
          }}
          width="30"
          height="30"
          className="d-inline-block align-text-top"
        />
        Happy Solution
      </a>
      <button
        className="navbar-toggler"
        type="button"
        onClick={handleToggleNav}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
        id="navbarNav"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link " href="#installation-guide">
              Installation Guide
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#cli-command">
              CLI Command
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#public-endpoint">
              Public Endpoint
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#statesync">
              Statesync
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#snapshot">
              Snapshot
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#explorers">
              Explorers
            </a>
          </li>
          {/* <li className="nav-item">
            <a className="nav-link" href="#node-maps">Node Maps</a>
          </li> */}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;

import React from "react";
import Logo from "../asset/logo.jpeg";
import Logo1 from "../asset/unnamed.jpg";

const Snapshot = () => {
  const a =
    "SNAP_NAME=$(curl -s https://snap1.happysolution.online/crossfi/ | egrep -o";
  const b = '">crossfi-snapshot.*.tar.lz4" | tr -d ">")';
  // eslint-disable-next-line
  const c = "${SNAP_NAME}";
  return (
    <div id="snapshot">
      <div className="im">
        <img
          src={Logo}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
        <img
          src={Logo1}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
      </div>
      <h1 className="lh-base text-break">Snapshot</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          sudo systemctl stop crossfid
          <br />
          cp $HOME/.mineplex-chain/data/priv_validator_state.json
          $HOME/.mineplex-chain/priv_validator_state.json.backup
          <br />
          rm -rf $HOME/.mineplex-chain/data
          <br />
          <br />
          {a}
          {b}
          <br />
          <br />
          curl https://snap1.happysolution.online/crossfi/{c} | lz4 -dc - | tar
          -xf - -C $HOME/.mineplex-chain
          <br />
          mv $HOME/.mineplex-chain/priv_validator_state.json.backup
          $HOME/.mineplex-chain/data/priv_validator_state.json
          <br />
          <br />
          sudo systemctl restart crossfid && journalctl -u crossfid -f
          --no-hostname -o cat
        </p>
      </div>
    </div>
  );
};

export default Snapshot;

import React from "react";
import Logo from "../asset/logo.jpeg";
import Logo1 from "../asset/unnamed.jpg";

const PublicEndpoint = () => {
  const RPC = "https://crossfi-testnet-rpc.happysolution.online/";
  const EVM_RPC = " https://crossfi-testnet-jsonrpc.happysolution.online/";
  const API = "https://crossfi-testnet-api.happysolution.online/";
  return (
    <div id="public-endpoint">
      <div className="im">
        <img
          src={Logo}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
        <img
          src={Logo1}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
      </div>
      <h2>Public Endpoint</h2>
      <h1 className="lh-base text-break">RPC</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        {RPC}
      </p>
      <h1 className="lh-base text-break">JSON RPC</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        {EVM_RPC}
      </p>
      <h1 className="lh-base text-break">API</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        {API}
      </p>
      {/* <h1 className="lh-base text-break">gRPC</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10"></p>
      <h1 className="lh-base text-break">Peers and Seeds</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10"></p>
      <h1 className="lh-base text-break">Peers</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10"></p>
      <h1 className="lh-base text-break">Seeds</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10"></p>
      <h1 className="lh-base text-break">Genesis & Address Book</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10"></p>
      <h1 className="lh-base text-break">Address Book</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10"></p>
      <h1 className="lh-base text-break">Genesis</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10"></p> */}
    </div>
  );
};

export default PublicEndpoint;

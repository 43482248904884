import React from "react";
import Logo from "../asset/logo.jpeg";
import Logo1 from "../asset/unnamed.jpg";

const Explorers = () => {
  const l = "https://testnet-explorer.happysolution.online";
  return (
    <div id="explorers">
      <div className="im">
        <img
          src={Logo}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
        <img
          src={Logo1}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
      </div>
      <h1 className="lh-base text-break">Custom Explorers</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        {l}
      </p>
    </div>
  );
};

export default Explorers;

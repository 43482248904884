import React from "react";
import Logo from "../asset/logo.jpeg";
import Logo1 from "../asset/unnamed.jpg";
import Logo2 from "../asset/named.jpg";
const LandingPage = () => {
  const handleEnterClick = () => {
    window.open("/happysolutionguide", "_blank");
  };
  const handleEntermainnetc = () => {
    window.open(
      "https://xfiscan.com/validators/mxvaloper1wptq85f7utm9qkcfj3ahrmf2ss6l88l33tm2qt",
      "_blank"
    );
  };

  const handleEntertestnetc = () => {
    window.open(
      "https://test.xfiscan.com/validators/mxvaloper1w8yv5rg7d9hs8ffe8g54e94ztg7ew5axna2hg4",
      "_blank"
    );
  };
  const handleEntermainneti = () => {
    window.open("/Mainnetiguide", "_blank");
  };

  const handleEntertestneti = () => {
    window.open("https://app.testnet.initia.xyz/stake", "_blank");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <a className="navbar-brand" href="#">
          <img
            src={Logo}
            alt="Logo"
            style={{
              borderRadius: "50px",
              marginRight: "1rem",
              marginLeft: "1rem",
            }}
            width="30"
            height="30"
            className="d-inline-block align-text-top"
          />
          Happy Solution
        </a>
      </nav>
      <div className="container landing-page text-center ">
        {/* <div className="card mt-3 text-center border border-3 border-warning rounded p-2 bg-warning bg-opacity-20 text-light"> */}
        <div className="card-header">
          <div className="im">
            <img
              src={Logo}
              alt="Logo"
              width="150"
              height="150"
              style={{ borderRadius: "100px" }}
              className="d-inline-block
              align-text-top"
            />
          </div>
          <h1 className="im">Happy Solution</h1>
        </div>
        <div className="card-body">
          <h5 className="card-text">
            a Happy node Solution to stake and secure the network
          </h5>
          <p>Team : Seaflag Crypto & community members</p>
          <p>Mail id: contact@happysolution.online</p>
        </div>
        {/* </div> */}
        <div className="card mt-3 text-center border border-3 border-warning rounded p-2 bg-warning bg-opacity-10 text-light">
          <div className="card-header">
            <div>
              <img
                src={Logo1}
                alt="Logo"
                width="150"
                height="150"
                style={{ borderRadius: "100px" }}
                className="d-inline-block
              align-text-top"
              />
            </div>
          </div>
          <div className="card-body">
            {/* <h3 className="card-title">Building Bridges</h3> */}
            <h5 className="card-text"> 🌐 CrossFi Chain </h5>
            <button
              className="btn border-info bg-warning bg-opacity-10 text-light"
              onClick={handleEnterClick}
            >
              Guide
            </button>
            <br />
            &ensp; To Stake MPX:&ensp;
            <br />
            <div className="bsp">
              <button
                className="btn border-info bg-warning bg-opacity-10 text-light"
                onClick={handleEntermainnetc}
              >
                🟢 Mainnet
              </button>
              &ensp;
              <button
                className="btn border-info bg-warning bg-opacity-10 text-light"
                onClick={handleEntertestnetc}
              >
                🟢 Testnet
              </button>
            </div>
          </div>
        </div>
        <div className="card mt-3 text-center border border-3 border-warning rounded p-2 bg-warning bg-opacity-10 text-light">
          <div className="card-header">
            <div>
              <img
                src={Logo2}
                alt="Logo"
                width="150"
                height="150"
                style={{ borderRadius: "100px" }}
                className="d-inline-block
              align-text-top"
              />
            </div>
          </div>
          <div className="card-body">
            {/* <h3 className="card-title">Bridging the Gap</h3> */}
            <h5 className="card-text">🌐 Initia Chain</h5>
            &ensp; To Stake INIT:&ensp;
            <div className="bsp">
              <button
                className="btn border-info bg-warning bg-opacity-10 text-light"
                onClick={handleEntermainneti}
              >
                🔴 Mainnet
              </button>
              &ensp;
              <button
                className="btn border-info bg-warning bg-opacity-10 text-light"
                onClick={handleEntertestneti}
              >
                🟢 Testnet
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;

import React from "react";
import Logo from "../asset/logo.jpeg";
import Logo1 from "../asset/unnamed.jpg";

const InstallationGuide = () => {
  const a = ">>";
  const b = "<<";
  const c = "<";
  const d = ">";

  return (
    <div id="installation-guide">
      <div className="im">
        <img
          src={Logo}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
        <img
          src={Logo1}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
      </div>
      <h2>Installation Guide</h2>
      <h1 className="lh-base text-break">
        How To Install Full Node Crossfi Testnet Setting up vars
      </h1>
      <p>Your Nodename (validator) that will shows in explorer</p>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        NODENAME= {c}Your_Nodename_Moniker{d}
      </p>
      <p>Save variables to system</p>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          echo "export NODENAME=$NODENAME" {a} $HOME/.bash_profile
          <br />
          if [ ! $WALLET ]; then <br />
          &emsp;&emsp;echo "export WALLET=wallet" {a} $HOME/.bash_profile
          <br />
          fi
          <br />
          echo "export CROSSFI_CHAIN_ID=crossfi-evm-testnet-1" {a}
          $HOME/.bash_profile
          <br />
          source $HOME/.bash_profile
        </p>
      </div>
      <h1 className="lh-base">Update packages</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        sudo apt update && sudo apt upgrade -y
      </p>
      <h1 className="lh-base">Install dependencies</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        sudo apt install curl build-essential git wget jq make gcc tmux
        net-tools ccze -y
      </p>
      <h1 className="lh-base">Install go</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          if ! [ -x "$(command -v go)" ]; then
          <br />
          &emsp;&emsp;ver="1.20.2" <br />
          &emsp;&emsp;cd $HOME
          <br />
          &emsp;&emsp;wget "https://golang.org/dl/go$ver.linux-amd64.tar.gz"
          <br />
          &emsp;&emsp;sudo rm -rf /usr/local/go
          <br />
          &emsp;&emsp;sudo tar -C /usr/local -xzf "go$ver.linux-amd64.tar.gz"
          <br />
          &emsp;&emsp;rm "go$ver.linux-amd64.tar.gz"
          <br />
          &emsp;&emsp;echo "export PATH=$PATH:/usr/local/go/bin:$HOME/go/bin"
          {a} &emsp;&emsp;~/.bash_profile
          <br />
          &emsp;&emsp;source ~/.bash_profile
          <br />
          fi
        </p>
      </div>
      <h1 className="lh-base">Download and build binaries</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          cd $HOME
          <br />
          wget
          https://github.com/crossfichain/crossfi-node/releases/download/v0.3.0-prebuild3/crossfi-node_0.3.0-prebuild3_linux_amd64.tar.gz
          <br />
          tar -xvf crossfi-node_0.3.0-prebuild3_linux_amd64.tar.gz
          <br />
          chmod +x $HOME/bin/crossfid
          <br />
          mv $HOME/bin/crossfid $HOME/go/bin
          <br />
          rm -rf crossfi-node_0.3.0-prebuild3_linux_amd64.tar.gz $HOME/bin
        </p>
      </div>
      <h1 className="lh-base">Init app</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          crossfid init $NODENAME --chain-id $CROSSFI_CHAIN_ID
          <br />
          rm -rf testnet ~/.mineplex-chain git clone
          <br />
          https://github.com/crossfichain/testnet.git
          <br />
          mv $HOME/testnet/ $HOME/.mineplex-chain/
        </p>
      </div>
      <h1 className="lh-base">Download configuration</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          wget https://snap1.happysolution.online/crossfi/genesis.json -O
          $HOME/.mineplex-chain/config/genesis.json
          <br />
          wget https://snap1.happysolution.online/crossfi/addrbook.json -O
          $HOME/.mineplex-chain/config/addrbook.json
        </p>
      </div>
      <h1 className="lh-base">Set the minimum gas price</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        sed -i 's|minimum-gas-prices =.*|minimum-gas-prices =
        "10000000000000mpx"|g' $HOME/.mineplex-chain/config/app.toml
      </p>
      <h1 className="lh-base">Disable indexing</h1>
      <p className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        sed -i -e "s/^indexer *=.*/indexer = \"null\"/"
        $HOME/.mineplex-chain/config/config.toml
      </p>
      <h1 className="lh-base">Config pruning</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          pruning="custom" && \<br />
          pruning_keep_recent="100" && \<br />
          pruning_keep_every="0" && \<br />
          pruning_interval="10" && \<br />
          sed -i -e "s/^pruning *=.*/pruning = \"$pruning\"/"
          ~/.mineplex-chain/config/app.toml && \<br />
          sed -i -e "s/^pruning-keep-recent *=.*/pruning-keep-recent =
          \"$pruning_keep_recent\"/" ~/.mineplex-chain/config/app.toml && \
          <br />
          sed -i -e "s/^pruning-keep-every *=.*/pruning-keep-every =
          \"$pruning_keep_every\"/" ~/.mineplex-chain/config/app.toml && \<br />
          sed -i -e "s/^pruning-interval *=.*/pruning-interval =
          \"$pruning_interval\"/" ~/.mineplex-chain/config/app.toml
        </p>
      </div>
      <h1 className="lh-base">Create service</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          sudo tee /etc/systemd/system/crossfid.service {d} /dev/null {b}EOF
          <br />
          [Unit] <br />
          Description=Crossfi node
          <br />
          After=network-online.target
          <br />
          [Service]
          <br />
          User=$USER
          <br />
          WorkingDirectory=$HOME/.mineplex-chain
          <br />
          ExecStart=$(which crossfid) start --home $HOME/.mineplex-chain
          <br />
          Restart=on-failure
          <br />
          RestartSec=5
          <br />
          LimitNOFILE=65535
          <br />
          [Install]
          <br />
          WantedBy=multi-user.target
          <br />
          EOF
          <br />
        </p>
      </div>
      <h1 className="lh-base">Register and start service</h1>
      <div className="border border-3 border-warning rounded p-2  bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          sudo systemctl daemon-reload
          <br />
          sudo systemctl enable crossfid
          <br />
          sudo systemctl restart crossfid && sudo journalctl -u crossfid -f -o
          cat
        </p>
      </div>
    </div>
  );
};

export default InstallationGuide;

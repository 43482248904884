import React, { useEffect } from "react";
import NavBar from "./components/NavBar";
import InstallationGuide from "./components/InstallationGuide";
import CLICommand from "./components/CLICommand";
import PublicEndpoint from "./components/PublicEndpoint";
import StateSync from "./components/StateSync";
import Snapshot from "./components/Snapshot";
import Explorers from "./components/Explorers";
import Footer from "./components/footer";
const App = () => {
  useEffect(() => {
    var contentDivs = document.querySelectorAll(".container > div");
    contentDivs.forEach(function (div) {
      div.style.display = "none";
    });
    var installationGuideDiv = document.getElementById("installation-guide");
    if (installationGuideDiv) {
      installationGuideDiv.style.display = "block";
    }
    document.querySelectorAll(".navbar-nav a").forEach(function (link) {
      link.addEventListener("click", function (event) {
        event.preventDefault();
        var targetId = link.getAttribute("href").substring(1);
        showContent(targetId);
      });
    });
    function showContent(targetId) {
      contentDivs.forEach(function (div) {
        div.style.display = "none";
      });
      var targetDiv = document.getElementById(targetId);
      if (targetDiv) {
        targetDiv.style.display = "block";
      }
    }
  }, []);

  return (
    <div>
      <NavBar />
      <div className="container mt-5  ">
        <InstallationGuide />
        <CLICommand />
        <PublicEndpoint />
        <StateSync />
        <Snapshot />
        <Explorers />
      </div>
      <Footer />
    </div>
  );
};

export default App;

import React from "react";
const Main = () => {
  return (
    <div>
      <h1>Yet to develop</h1>
    </div>
  );
};

export default Main;

import React from "react";
import Logo from "../asset/logo.jpeg";
import Logo1 from "../asset/unnamed.jpg";

const StateSync = () => {
  return (
    <div id="statesync">
      <div className="im">
        <img
          src={Logo}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
        <img
          src={Logo1}
          alt="Logo"
          width="150"
          height="150"
          style={{ borderRadius: "100px" }}
          className="d-inline-block
              align-text-top"
        />
      </div>
      <h1 className="lh-base text-break">StateSync</h1>
      <div className="border border-3 border-warning rounded p-2 bg-warning bg-opacity-10">
        <p className="lh-base font-monospace">
          sudo systemctl stop crossfid
          <br />
          <br />
          cp $HOME/.mineplex-chain/data/priv_validator_state.json
          $HOME/.mineplex-chain/priv_validator_state.json.backup <br />
          crossfid tendermint unsafe-reset-all --home $HOME/.mineplex-chain
          --keep-addr-book <br /> <br />
          SNAP_RPC="https://crossfi-testnet-rpc.happysolution.online:443" <br />
          <br />
          LATEST_HEIGHT=$(curl -s $SNAP_RPC/block | jq -r
          .result.block.header.height); \ <br />
          BLOCK_HEIGHT=$((LATEST_HEIGHT - 2000)); \ <br />
          TRUST_HASH=$(curl -s "$SNAP_RPC/block?height=$BLOCK_HEIGHT" | jq -r
          .result.block_id.hash) <br />
          <br />
          echo $LATEST_HEIGHT $BLOCK_HEIGHT $TRUST_HASH <br />
          <br />
          sed -i -E "s|^(enable[[:space:]]+=[[:space:]]+).*$|\1true| ; \ <br />
          s|^(rpc_servers[[:space:]]+=[[:space:]]+).*$|\1\"$SNAP_RPC,$SNAP_RPC\"|
          ; \ <br />
          s|^(trust_height[[:space:]]+=[[:space:]]+).*$|\1$BLOCK_HEIGHT| ; \
          <br />
          s|^(trust_hash[[:space:]]+=[[:space:]]+).*$|\1\"$TRUST_HASH\"|"
          $HOME/.mineplex-chain/config/config.toml <br />
          <br />
          mv $HOME/.mineplex-chain/priv_validator_state.json.backup
          $HOME/.mineplex-chain/data/priv_validator_state.json <br />
          <br />
          sudo systemctl restart crossfid <br />
          sudo journalctl -u crossfid -f --no-hostname -o cat
        </p>
      </div>
    </div>
  );
};

export default StateSync;

import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import LoadingScreen from "./components/LoadingScreen";
import App from "./App";
import Main from "./main";
import LandingPage from "./components/LandingPage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./index.css";

const Root = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [redirectToApp, setRedirectToApp] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const handleRedirectToApp = () => {
    setRedirectToApp(true);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            redirectToApp ? (
              <Navigate to="/happysolutionguide" />
            ) : (
              <LandingPage onButtonClick={handleRedirectToApp} />
            )
          }
        />
        <Route path="/happysolutionguide" element={<App />} />
        <Route path="Mainnetiguide" element={<Main />} />
      </Routes>
    </Router>
  );
};

createRoot(document.getElementById("root")).render(<Root />);
